// init base functions
$(window).on('load', function () {
    classOnBody($('main').attr('id'));
    scrollDirection();
    initGettersAndSetters();
    changeTouchClickText();
});
$(window).on('hashchange', function () {
    initGettersAndSetters();
});

// general input masks
if (is.not.ie()) {
    $('.cepMask').mask("99999-999");
    $('.cpfMask').mask("999.999.999-99");
    $('.dataMask').mask("99/99/9999");
    $('.foneMask').focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if (phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');
}

// init AOS
if (is.not.ie()) {
    AOS.init();
} else {
    $('*').removeAttr("data-aos");
}
if (is.mobile()) {
    $('.l-headerHome *').removeAttr("data-aos");
}

// components
if ($('.liSelect').length) {
    $('.liSelect select').on('focusin', function () {
        $(this).parent('.liSelect').addClass('is-active');
    });
    $('.liSelect select').on('focusout', function () {
        $(this).parent('.liSelect').removeClass('is-active');
    });
}
if ($('.c-cardPost').length) {
    limitCaracters($('.c-cardPost__content p'), 210);
}

// sections
if($('.s-instagram').length) {
    // Instagram
    var userFeed = new Instafeed({
        get: 'user',
        userId: '364030108',
        accessToken: '364030108.1677ed0.5e94ad1454244bddb119a4d5860af412',
        limit: 9,
        resolution: 'standard_resolution',
        template: '<li><a href="{{link}}" target="_blank" class="u-aspectRatio u-aspectRatio--1-1"><img src="{{image}}" alt="{{caption}}" class="instaImg"/></a></li>',
    });
    userFeed.run();
}
if($('.l-footer').length) {
    var testemonials__swiper = new Swiper('.testemonials__swiper', {
        speed: 400,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        autoplay: {
            delay: 10000,
        },
    });
}

// nav
if ($('.l-nav').length) {
    // vars
    var nav_scrollInit = 0;
    var nav_classActive = 'is-active';
    var nav_el = {
        body: $('body'),
        hamburguer: $('.c-hamburguer'),
        mask: $('.l-nav__maskMenu'),
        nav: $('.l-nav'),
        navContentHero: $('.l-nav__contentHero'),
        liWithOneLevelSubMenu: $('.liWithOneLevelSubMenu > a'),
        liWithTwoLevelsSubMenu: $('.liWithTwoLevelsSubMenu > a'),
        liWithoutSubMenu: $('.liWithoutSubMenu > a'),
        firstSubMenu: $('.firstSubMenu'),
        secondSubMenu: $('.secondSubMenu'),
        openSecondSubMenu: $('.openSecondSubMenu'),
    };

    // functions
    function showMask() {
        nav_el.mask.addClass(nav_classActive);
    }
    function hideMask() {
        nav_el.mask.removeClass(nav_classActive);
    }
    function closeAllSubMenus() {
        nav_el.liWithOneLevelSubMenu.parent('li').removeClass(nav_classActive);
        nav_el.liWithTwoLevelsSubMenu.parent('li').removeClass(nav_classActive);
    }
    function overflowBlocked() {
        nav_el.body.addClass('u-overflowBlocked');
    }
    function overflowFree() {
        nav_el.body.removeClass('u-overflowBlocked');
    }
    function closeNavContent() {
        nav_el.navContentHero.removeClass(nav_classActive);
    }
    function openNavContent() {
        nav_el.navContentHero.addClass(nav_classActive);
    }
    function inactiveHamburguer() {
        nav_el.hamburguer.removeClass(nav_classActive);
    }
    function activeHamburguer() {
        nav_el.hamburguer.addClass(nav_classActive);
    }

    function closeAllMenuItens() {
        hideMask();
        closeAllSubMenus();
        overflowFree();
        closeNavContent();
        inactiveHamburguer();
    }
    function openAllMenuItens() {
        showMask();
        overflowBlocked();
        openNavContent();
        activeHamburguer()
    }

    // effect on scroll
    $(window).on('scroll', function(event) {
        var scrollBody = $(this).scrollTop();

        // scroll up to 99
        if (scrollBody > 99) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }

        // middle class
        if (scrollBody > 600) {
            nav_el.nav.addClass('hidden');
            nav_el.nav.addClass('scrolledMiddle');
        } else {
            nav_el.nav.removeClass('hidden');
            nav_el.nav.removeClass('scrolledMiddle');
        }

        // scroll up or down
        if (scrollBody < nav_scrollInit) {
            nav_el.nav.removeClass('hidden');
            nav_el.nav.addClass('scrolledUp');
            nav_el.nav.removeClass('scrolledDown');
        } else {
            nav_el.nav.removeClass('scrolledUp');
            nav_el.nav.addClass('scrolledDown');
        }

        // close menus on hidden nav
        if(nav_el.nav.hasClass('hidden')) {
            closeAllMenuItens();
        }

        // reference var
        nav_scrollInit = scrollBody;
    });
    $(window).on('load', function(event) {
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 1) {
            nav_el.nav.addClass('scrolled');
        } else {
            nav_el.nav.removeClass('scrolled');
        }
    });

    // open/close/menus/submenus/hamburguer on clicks
    nav_el.hamburguer.on('click', function() {
        if ($(this).hasClass(nav_classActive)) {
            closeAllMenuItens();
        } else {
            openAllMenuItens();
        }
    });
    nav_el.mask.on('click', function() {
        closeAllMenuItens()
    });
    nav_el.liWithoutSubMenu.on('click', function() {
        closeAllMenuItens();
    });
    nav_el.firstSubMenu.children('li').on('click', function() {
        if(!$(this).hasClass('openSecondSubMenu')) {
            closeAllMenuItens();
        }
    });
    nav_el.secondSubMenu.children('li').on('click', function() {
        closeAllMenuItens();
    });
}
if ($('.l-nav--withSubMenus').length) {
    // open close submenus with clicks
    nav_el.liWithOneLevelSubMenu.on('click', function (event) {
        event.preventDefault();
        if (is.mobile()) {
            // mobile
            $(this).parent('li').toggleClass(nav_classActive);
            $(this).parent('li').children('a').toggleClass(nav_classActive);
            showMask();
        } else {
            // desktop
            nav_el.liWithOneLevelSubMenu.parent('li').removeClass(nav_classActive);
            nav_el.liWithTwoLevelsSubMenu.parent('li').removeClass(nav_classActive);
            $(this).parent('li').addClass(nav_classActive);
            showMask();
        }
    });
    nav_el.liWithTwoLevelsSubMenu.on('click', function (event) {
        event.preventDefault();
        if (is.mobile()) {
            // mobile
            $(this).parent('li').toggleClass(nav_classActive);
            $(this).parent('li').children('a').toggleClass(nav_classActive);
            showMask()
        } else {
            // desktop
            nav_el.liWithOneLevelSubMenu.parent('li').removeClass(nav_classActive);
            nav_el.liWithTwoLevelsSubMenu.parent('li').removeClass(nav_classActive);
            $(this).parent('li').addClass(nav_classActive);
            showMask()
        }
    });
    nav_el.openSecondSubMenu.on('click', function(event) {
        // event.preventDefault();
        if (is.mobile()) {
            $(this).children('.secondSubMenu').toggleClass(nav_classActive);
        } else {
            // desktop
            nav_el.secondSubMenu.removeClass(nav_classActive);
            $(this).children('.secondSubMenu').addClass(nav_classActive);
        }
    });
}

// modules
if ($('.wppLink').length) {
    var number = 5548991382985;

    // this var have an options, elements and others things of the module
    var wppl = {
        el: $('.wppLink'),
        icon: $('.js-openWhats'),
        infos: $('.wppLink__infos'),
        form: $('.wppLink__form'),
        formBtn: $('#btnWhats'),
        close: $('.wppLink__close')
    };

    // this var have an options and parameters of the link
    var wppl__parts = {
        link_mobile: 'http://api.whatsapp.com/',
        link_web: 'https://web.whatsapp.com/',
        phone: 'send?phone=',
        msg: 'text='
    };

    // make the final link
    wppl.icon.on('click', function() {
        if (is.iphone() || is.androidPhone() || is.windowsPhone() || is.blackberry() || is.mobile()) {
            // MOBILE VERSION
            if (!wppl.el.hasClass('wppLink--withForm')) {
                window.open(wppl__parts.link_mobile + wppl__parts.phone + number, '_blank');
            } else {
                toggleForm();
            }
        } else {
            // WEB VERSION
            if (!wppl.el.hasClass('wppLink--withForm')) {
                window.open(wppl__parts.link_web + wppl__parts.phone + number, '_blank');
            } else {
                toggleForm();
            }
        }
    });
    wppl.formBtn.on('click', function(e) {
        var userName = $('#formWhats_nome').val();
        var userMsg = $('#formWhats_mensagem').val();
        userFullMsg = 'Olá, me chamo *' + userName + '* E tenho essa mensagem: *' + userMsg + '*';
        if (is.iphone() || is.androidPhone() || is.windowsPhone() || is.blackberry() || is.mobile()) {
            window.open(wppl__parts.link_mobile + wppl__parts.phone + number + '&' + wppl__parts.msg + userFullMsg, '_blank');
        } else {
            window.open(wppl__parts.link_web + wppl__parts.phone + number + '&' + wppl__parts.msg + userFullMsg, '_blank');
        }
    });
    function toggleForm() {
        wppl.form.toggleClass('is-active');
        wppl.form.removeClass('u-dnone');
    }
    wppl.close.on('click', function() {
        wppl.form.removeClass('is-active');
    });

    // check modifiers and remove useless elements
    if (wppl.el.hasClass('wppLink--hideNumber')) {$('.wppLink .wppNumber').remove();}
    if (wppl.el.hasClass('wppLink--hideCaption')) {$('.wppLink .wppCaption').remove();}
    if (wppl.el.hasClass('wppLink--onlyIcon')) {$('.wppLink .wppLink__infos').remove();}
    if (!wppl.el.hasClass('wppLink--withForm')) {$('.wppLink .wppLink__form').remove();}

    // check dispositive and remove useless elements
    if(is.mobile()){
        // removeCaption
        $('.wppLink .wppCaption').remove();
        if (!wppl.el.hasClass('wppLink--hideNumber')) {
            wppl.el.addClass('wppLink--hideCaption');
        }

        if (wppl.el.hasClass('wppLink--disableFormInMobile')) {
            wppl.form.remove();
            wppl.el.removeClass('wppLink--withForm');
        }
    }
}
if ($('#w_ieDetect').length) {
    function showMessageOnIE(warnLevel) {
        // warnLevel = 1 -> Remover aviso
        // warnLevel = 2 -> Recolher aviso
        // warnLevel = 3 -> Bloquear navegação

        var ieDetectEl = $('#w_ieDetect');
        var ieDetectEl__mask = $('#w_ieDetect__mask');
        var ieDetectEl__unlock = $('.w_ieDetect__unlockSite');

        if (is.ie()) {
            console.log('IE DETECTED')
            if(warnLevel == 1) {
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');

                ieDetectEl__unlock.on('click', function() {
                    ieDetectEl.remove();
                    ieDetectEl__mask.remove();
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel == 2) {
                //block overflow and show modal
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');

                // hide modal and mask
                ieDetectEl__unlock.on('click', function() {
                    $(this).remove();
                    ieDetectEl__mask.remove();
                    ieDetectEl.removeClass('is-visible');
                    ieDetectEl.addClass('is-fixed');
                    $('body').css('overflow-y', 'auto');
                    $('body').css('overflow-x', 'hidden');
                });
            }
            if(warnLevel == 3) {
                $('nav').remove();
                $('header').remove();
                $('footer').remove();
                $('main').remove();
                $('section').remove();
                ieDetectEl__unlock.remove();
                $('body').css('overflow', 'hidden');
                ieDetectEl.removeClass('u-dnone').addClass('is-visible');
                ieDetectEl__mask.removeClass('u-dnone').addClass('is-visible');
            }
        } else {
            ieDetectEl.remove();
            ieDetectEl__mask.remove();
        }
    }
    $(window).on('load', showMessageOnIE(3));
}